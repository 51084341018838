/**
 * This file is meant to be used for when accessing state about all incidents
 */
import { INCIDENT_FILTER_QUERY_FIELD } from 'config/constants';
import { localStorageEffect } from 'data/effect';
import _sortBy from 'lodash/sortBy';
import { atom, selector, selectorFamily } from 'recoil';
import { CoordinateSystem, Incident, INCIDENT_LABEL, IncidentLabel, IncidentMarkPosition, Mark } from 'types';
import { getGDACoordinatesFromLonLat } from 'utils/geoLocation';
import { getIncidentLabel } from 'utils/incident';
import { incidentResolutionTitle, stringifyGDACoordinates, stringifyLonLat } from 'utils/map';

import { rsaUserCoordinateSystem } from '../authStore';

/**
 * @description Whether the user is currently marking an incident
 */
export const rsiIsMarkingFire = atom<boolean>({
  key: 'rsiIsMarkingFire',
  default: false,
});

/**
 * @description A user's current incident mark
 */
export const rsiIncidentMark = atom<Mark>({
  key: 'rsiIncidentMark',
  default: null,
});

/** Incidents hovered */
export const rsiHoverIncidentsId = atom<number[]>({
  key: 'rsiHoverIncidentsId',
  default: [],
});

/** Keyword to search incident/station list */
export const rsiKeywordQuery = atom<string>({
  key: 'rsiKeywordQuery',
  default: '',
});

/**
 * The id of the selected camera for the incident
 */
export const rsiSelectedStationId = atom<number>({
  key: 'rsiSelectedStationId',
  default: null,
});

/** Keyword to search incident/station list */
export const rsiFilterQuery = atom<IncidentLabel[]>({
  key: 'rsiFilterQuery',
  default: [],
  effects: [localStorageEffect(INCIDENT_FILTER_QUERY_FIELD, [])],
});

export const rsiIsFiltersCustomized = selector({
  key: 'rsiIsFiltersCustomized',
  get: ({ get }) => {
    const filterQueries = get(rsiFilterQuery);

    return filterQueries?.length > 0;
  },
});

/** Whether open the share incident modal. */
export const rsiShareModalOpen = atom<boolean>({
  key: 'rsiShareModalOpen',
  default: false,
});

/** The new incident to create */
export const rsiNewIncidentToCreate = atom<Incident>({
  key: 'rsiNewIncidentToCreate',
  default: null,
});

/** Last time we fetch the full incidents list */
export const rsiUserIncidentsTs = atom<number>({
  key: 'rsiUserIncidentsTs',
  default: 0,
});

/** Call API to fetch all incidents (probably with up to 10k incidents) */
export const rsiUserIncidents = atom<Incident[]>({
  key: 'rsiUserIncidents',
  default: [],
});

/** The incident to display on the IncidentDetails page */
export const rsiFeaturedIncident = atom<Incident>({
  key: 'rsiFeaturedIncident',
  default: null,
});

/** The last time the featuredIncident was fetched */
export const rsiFeaturedIncidentTs = atom<number>({
  key: 'rsiFeaturedIncidentTs',
  default: 0,
});

/** The Incident mark position for the popup */
export const rsiIncidentMarkPositionForPopup = atom<IncidentMarkPosition>({
  key: 'rsiIncidentMarkPositionForPopup',
  default: null,
});

/**
 * User incidents sorted for Incidents List
 */
export const rsiSortedUserIncidents = selector<Incident[]>({
  key: 'rsiSortedUserIncidents',
  get: ({ get }) => {
    const userIncidents = get(rsiUserIncidents);

    return _sortBy(userIncidents, ['order', 'startTime']).reverse();
  },
});

/**
 * @description Returns the live Incidents that have any camera for the provided station ID
 * live excludes ended and dismissed incidents
 */
export const rsiUserIncidentsForStation = selectorFamily<Incident[], number>({
  key: 'rsiUserIncidentsForStation',
  get:
    (stationId) =>
    ({ get }) => {
      const userIncidents = get(rsiUserIncidents);

      return userIncidents.filter((incident) => {
        const isAnyCameraFromStation = incident.cameras.some((camera) => camera.id === stationId);

        return !incident.endTime && !incident.closeTime && isAnyCameraFromStation;
      });
    },
});

/**
 * @warning - Please prefer getting the data you need, avoid passing around whole incident
 * @description Returns the Incident with the given id
 */
export const rsiIncidentById = selectorFamily<Incident, number>({
  key: 'rsiIncidentById',
  get:
    (incidentId) =>
    ({ get }) => {
      const userIncidents = get(rsiUserIncidents);

      return userIncidents.find((incident) => incident.id === incidentId);
    },
});

/**
 * @description Returns the Incident Title of the incdent with the given id
 */
export const rsiIncidentTitleById = selectorFamily<string, number>({
  key: 'rsiIncidentTitleById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));
      const label = getIncidentLabel(incident);

      if (!incident) {
        return '';
      }

      return `${incident?.name || incidentResolutionTitle[label]} - #${incident?.id || ''}`;
    },
});

/**
 * @description Returns the Incident Coordinates
 * - If the incident is gcs: GDA2020 return GDA coords
 * - If the incident is gcs: WGS84 return WGS coords
 */
export const rsiIncidentPrimaryCoordinatesById = selectorFamily<string, number>({
  key: 'rsiIncidentPrimaryCoordinatesById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));
      const coordinateSystem = get(rsaUserCoordinateSystem);

      const lonLat = { lat: incident?.lat, lon: incident?.lon };

      if (!incident || !incident?.lat || !incident?.lon) {
        return null;
      } else if (coordinateSystem === CoordinateSystem.GDA2020) {
        return stringifyGDACoordinates(getGDACoordinatesFromLonLat(lonLat));
      }

      return stringifyLonLat(lonLat);
    },
});

/**
 * @description Returns the Incident Display sourceType and background color
 */
export const rsiIncidentDisplayById = selectorFamily<[string, string], number>({
  key: 'rsiIncidentDisplayById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident = get(rsiIncidentById(incidentId));

      let [sourceType, backgroundColor] = [incident?.source, 'primary.main'];
      const label = getIncidentLabel(incident);

      switch (label) {
        case INCIDENT_LABEL.CLOSED:
          sourceType = 'closed';
          backgroundColor = 'greys.light';
          break;
        case INCIDENT_LABEL.DISMISSED:
          backgroundColor = 'greys.light';
          break;
        case INCIDENT_LABEL.CONFIRMED:
          sourceType = 'burn';
          backgroundColor = 'secondary.main';
          break;
        case INCIDENT_LABEL.PRESCRIBED:
          backgroundColor = 'greys.dark';
          break;
        case INCIDENT_LABEL.POSSIBLE:
          break;
        default:
          break;
      }

      return [sourceType, backgroundColor];
    },
});

/**
 * @description Returns whether this incident has multiple cameras associated with it
 */
export const rsiIncidentHasMultipleCamerasById = selectorFamily<boolean, number>({
  key: 'rsiIncidentById',
  get:
    (incidentId) =>
    ({ get }) => {
      const incident: Incident = get(rsiIncidentById(incidentId));

      return incident?.cameras?.length > 1;
    },
});

/** Whether the given Station is involved in the feature incident */
export const rsiIsStationInFeaturedIncident = selectorFamily<boolean, number>({
  key: 'rsiIsStationInFeaturedIncident',
  get:
    (stationId) =>
    ({ get }) => {
      const incident = get(rsiFeaturedIncident);

      return !!incident?.cameras?.some((camera) => {
        return camera?.id === stationId;
      });
    },
});

/** The id of the featured incident */
export const rsiFeaturedIncidentId = selector<number>({
  key: 'rsiFeaturedIncidentId',
  get: ({ get }) => {
    const incident = get(rsiFeaturedIncident);

    return incident?.id;
  },
});
